/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, FUNCTIONS){
	const a = 'is-active';
	const newsYearList = () => {
		if($('.js-news-yearList').length) {
			var latest = $('.js-news-latest-year').text();
			var earliest = $('.js-news-earliest-year').text();
			let directoryYear = location.pathname.split('/')[2];
			if(directoryYear == 'news') { //english
				directoryYear = location.pathname.split('/')[3];
			}
			if(directoryYear == 'library') { //ir-library-etc
				directoryYear = location.pathname.split('/')[4];
			}
			// テンプレート最新年リダイレクト
			// if (location.pathname.indexOf('template.html') != -1) {
			// 	if (location.pathname.indexOf('english') != -1) {
			// 		location.href = '/english/news/'+$('.js-news-latest-year').text()+'/';
			// 	} else {
			// 		location.href = '/news/'+$('.js-news-latest-year').text()+'/';
			// 	}
			// }
			// optionを追加
			for(var i =latest; i>=earliest; i--) {
				$('.js-news-yearList').append($('<option>').html(''+i+'').val(''+i+''));
			}
			// 見ている年をselected
			$('.js-news-yearList').val(directoryYear);
			// 選択した年に飛ばす
			$('.js-news-yearList').change(function(){
				location.href = location.pathname.replace(directoryYear, $(this).val());
			});
		}
	};
	const newsCurrent = () => {
		if($('.news-tab').length) {
			for(var i=0; i<$('.news-tab li a').length; i++) {
				if(location.pathname === $('.news-tab li a').eq(i).attr('href')) {
					$('.news-tab li a').eq(i).parent('li').addClass(a);
				}
			}
		}
		if($('.ir-library-etc').length) {
			if($('.ir-library-etc-tabChange-content').length) {
				$('.ir-library-etc-tabChange-content').eq(0).addClass(a);
				$('.ir-library-etc-tabChange-btn').eq(0).parent('li').addClass(a);
				$('.ir-library-etc-tabChange-btn').each(function(){
					$(this).on('click', function(){
						var index = $('.ir-library-etc-tabChange-btn').index(this);
						$('.ir-library-etc-tabChange-btn').parent('li').removeClass(a);
						$(this).parent('li').addClass(a);
						$('.ir-library-etc-tabChange-content').removeClass(a);
						$('.ir-library-etc-tabChange-content').eq(index).addClass(a);
					});
				});
			}
		}
	};
	newsYearList();
	newsCurrent();

})(window.jQuery, window.FUNCTIONS);
